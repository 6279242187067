//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import { toExportFormat } from "@/utils/export";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      prev: null
    };
  },
  methods: {
    refresh() {
      this.$refs.table.refreshSilent();
    },
    onRowClick(item) {
      if (item.sku && item.script_name) {
        this.$router.push({
          name: "RepricerEventsLog",
          params: {
            product: item.sku,
            script: item.script_name
          }
        });
      }
    },
    async tableLoader({
      query
    }) {
      if (query.action === "export") {
        query.page_size = 10000;
        query.page = 1;
      }
      let result = await this.$store.dispatch("repricer/getMyProducts", {
        ...query,
        ...this.reportSettings
      });
      if (query.action === "export") {
        return toExportFormat(result, this.columns);
      } else {
        result = {
          items: [...result.data.map(item => {
            return {
              ...item,
              productImage: `https://salesfinder.ru/api/getProductImage?mp=${this.reportSettings.mp}&sku=${item.sku}`
            };
          })],
          total: result.totals.total,
          success: result.success,
          message: result.message
        };
      }
      return result;
    }
  },
  computed: {
    columns() {
      return [{
        title: "SKU",
        show: true,
        name: "sku",
        width: 100,
        filter: "text"
      }, {
        title: "Товар",
        show: true,
        name: "product",
        type: "text",
        width: 300,
        getImage: item => item.productImage
      }, {
        title: "Скрипт",
        show: true,
        name: "script_name",
        width: 160,
        filter: "text"
      },
      // {
      //     title: "Минимальная цена",
      //     show: true,
      //     name: "min_price",
      //     type: "text",
      //     width: 160,
      //     filter: "numberRange",
      // },
      {
        title: "Актуальная цена",
        show: true,
        name: "current_price",
        type: "text",
        width: 160,
        filter: "numberRange"
      }, {
        title: "Средняя цена",
        show: true,
        name: "avg_price",
        type: "text",
        width: 160,
        filter: "numberRange"
      }, {
        title: "Последние изменение",
        show: true,
        name: "date_add",
        type: "datetimeLocal",
        width: 140,
        filter: "dateRange"
      }];
    }
  }
};