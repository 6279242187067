//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import DataLoading from "@/components/Loading/DataLoading";
import CalendarMixin from "@/mixins/Calendar";
import RepricerMixin from "@/mixins/Repricer";
import AppSelectMulti from "@/components/AppSelectMulti.vue";
import AppSelect from "@/components/AppSelect.vue";
import HasTokens from "@/components/HasTokensRepricer.vue";
import WarningRepricer from "@/components/Alert/WarningRepricer.vue";
import MyProductsTable from "@/components/Repricer/MyProductsTable.vue";
import ProductsManagementTable from "@/components/Repricer/ProductsManagementTable.vue";
import DataTableActions from "@/components/DataTable/DataTableActions.vue";
export default {
  mixins: [CalendarMixin, RepricerMixin],
  meta: {
    title: "Товары и динамика цен"
  },
  data() {
    const localStorageCalendar = JSON.parse(localStorage.getItem("repricerCalendar"));
    if (localStorageCalendar) {
      this.$store.dispatch("repricer/updateCalendar", localStorageCalendar);
    }
    return {
      calendarInternal: null,
      calendar: this.$store.state.repricer.calendar
    };
  },
  methods: {},
  computed: {
    tableKey() {
      return `${this.tabs_tables_model.id}`;
    },
    reportSettings() {
      const tokens = this.selectedTokens.map(item => item.id);
      if (!this.calendar) {
        return null;
      }
      if (!tokens.length) {
        return null;
      }
      const dates = [this.calendar[0], this.calendar[1]];
      return {
        mp: this.marketplace.id,
        date: dates[0],
        date2: dates[1],
        tokens
      };
    }
  },
  watch: {
    calendar() {
      this.$store.dispatch("repricer/updateCalendar", this.calendar);
      localStorage.setItem("repricerCalendar", JSON.stringify(this.calendar));
    }
  },
  components: {
    MainTitle,
    ReportPage,
    HasTokens,
    WarningRepricer,
    DataLoading,
    MyProductsTable,
    ProductsManagementTable,
    AppSelectMulti,
    AppSelect,
    DataTableActions
  }
};